.admin-manage-shop-applications {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.admin-manage-shop-applications h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.admin-manage-shop-applications .error-message {
  color: #ff3333;
}

/* Search input and clear button container */
.admin-manage-shop-applications .search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Search input styling */
.admin-manage-shop-applications .search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Clear button styling */
.admin-manage-shop-applications .clear-btn {
  padding: 10px 20px;
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-manage-shop-applications .clear-btn:hover {
  background-color: #d41811;
}

.admin-manage-shop-applications table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.admin-manage-shop-applications th, .admin-manage-shop-applications td {
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
}

.admin-manage-shop-applications th {
  background-color: #f2f2f2;
}

.admin-manage-shop-applications .action-btn {
  background-color: #000;
  color: #fff;
  padding: 8px 12px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
}

.admin-manage-shop-applications .decline-btn {
  background-color: #d9534f;
}

.admin-manage-shop-applications .action-btn:hover {
  background-color: #424242;
}

.admin-manage-shop-applications .decline-btn:hover {
  background-color: #d41811;
}
