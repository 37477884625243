.creativesHubNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: #ffffff;
  padding: 10px 20px;
  gap: 20px;
}

.creativesHubNavBar .back-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 55px;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.creativesHubNavBar .back-button:hover {
  background-color: #ffffff;
  color: #000000;
}


.creativesHubNavBar .nav-links {
  display: flex; 
  gap: 18px;
}

.creativesHubNavBar .nav-links a{
text-decoration: none;
color: #ffffff;
}

.creativesHubNavBar .cart-icon {
  position: relative;
  font-size: 1.2rem;
  color: white;
}

.creativesHubNavBar .cart-icon .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: red;
  color: white;
  font-size: 0.8rem;
  padding: 2px 5px;
  border-radius: 50%;
}


.creativesHubNavBar .markets-button {
  display: none; 
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 55px;
  color: #ffffff;
  background: none;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.creativesHubNavBar .markets-button:hover {
  background-color: #ffffff;
  color: #000000;
}

.creativesHubNavBar .markets-dropdown {
  position: relative;
  width: 85%;
}


.creativesHubNavBar .dropdown-menu {
  position: absolute;
  top: calc(100% + 10px); 
  left: 0;
  background: black;
  border: 1px solid #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}


.creativesHubNavBar .dropdown-menu a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.creativesHubNavBar .dropdown-menu a:hover {
  color: #cccccc;
}

/* Responsive Behavior */
@media only screen and (max-width: 650px) {
  .creativesHubNavBar .nav-links {
    display: none; 
  }

  .creativesHubNavBar .markets-button {
    display: flex; 
  }
}
