.creatives-hub-home-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  background: black;
  background-size: 400% 400%;
  animation: body 10s infinite ease-in-out;
  color: #ffffff;
  justify-items: center;
  text-align: center;
}

@media (max-width: 767px) {
  .creatives-hub-home-section {
      padding: 10px;
      gap: 10px;
  }
}

.creatives-hub-home-section > * {
  max-width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  box-sizing: border-box;
}