.CTA-section {
  display: flex;
  flex-direction: row;
}

.CTA-section .CTA-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.CTA-section .CTA-content h1 {
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  margin-top: 1.8rem;
  text-align: right;
}

.CTA-section .CTA-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-top: 1.5rem;
  text-align: right;
}

.CTA-section .CTA-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: row;
}

.CTA-section .CTA-content__input input:focus {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.6);
}


.CTA-section .CTA-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background-color: #ffffff;
  padding: 0 1rem;
  outline: none;
  color: #000000;
  border: 1px solid #000000;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 12px;
}

.CTA-section .CTA-content__input {
  position: relative;
}

.CTA-section .error-message {
  font-size: 14px;
  color: red;
  margin: 15px;
}


.CTA-section .CTA-content__input .get-started-link {
  flex: 0.8;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #000000;
  border: 2px solid #000000;
  padding: 0 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-decoration: none;
}

.CTA-section .CTA-content__input .get-started-link:hover {
  color: #ffffff;
  background-color: #3b3b3b;
  border: 2px solid rgb(0, 0, 0);
}

.CTA-section .CTA-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CTA-section .CTA-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .CTA-section {
    flex-direction: column;
  }

  .CTA-section .CTA-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .CTA-section .CTA-content h1 {
    font-size: 28px;
    line-height: 60px;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
  }

  .CTA-section .CTA-content p {
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
  }
  .CTA-section .CTA-content__input .get-started-link {
    font-size: 14px; 
  }
  .CTA-section .CTA-content__input {
    width: 98%;
    margin-right: 12px;
  }
}
