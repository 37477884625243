.admin-blog {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.admin-blog h1 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.blog-form {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 20px;
}

.admin-blog label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.admin-blog input,
.admin-blog textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-blog textarea {
  height: 150px;
  resize: vertical;
}

.admin-blog button {
  background-color: #000000;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-blog button:hover {
  background-color: #2b2b2b;
}

.admin-blog .error-message {
  color: #ff0000;
  margin-top: 20px;
}

.blog-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.image-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .admin-blog input,
  .admin-blog textarea {
    padding: 8px;
  }
  
  .blog-img {
    width: 100%;
  }
}
