.checkout-page {
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.checkout-header h2 {
    margin: 0;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
}

.checkout-form, .delivery-method, .terms-and-conditions {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout-form h3, .delivery-method h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #333;
    font-weight: 600;
}

.checkout-form label, .delivery-method label, .terms-and-conditions label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
}

.checkout-form input[type="text"],
.checkout-form input[type="email"],
.checkout-form input[type="tel"],
.delivery-method input[type="text"],
.delivery-method input[type="email"],
.delivery-method input[type="tel"],
.delivery-method select,
.delivery-method textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.delivery-method textarea {
    resize: vertical;
}

.delivery-method select {
    cursor: pointer;
}

.terms-and-conditions label {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.terms-and-conditions a {
    color: #007bff;
    text-decoration: underline;
    margin-left: 5px;
}

.submit-btn, .download-btn {
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.submit-btn:hover, .download-btn:hover {
    background-color: #414141;
}

.download-btn {
    background-color: #000000;
    color: #fff;
}

.download-btn:hover {
    background-color: #414141;
}

.back-to-cart-btn {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #6c757d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    margin-right: 10px; 
    font-weight: bold;
}

.back-to-cart-btn:hover {
    background-color: #000000;
}

@media (max-width: 768px) {
    .checkout-page {
        padding: 15px;
    }

    .checkout-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .submit-btn, .download-btn {
        width: 100%;
        margin-top: 10px;
    }

    .back-to-cart-btn {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px; 
    }
}
