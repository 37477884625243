.tailor-request {
    padding: 20px;
  }
  
  .tailor-request .intro, .selection, .details, .summary {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .tailor-request h2 {
    text-align: center;
    font-size: 28px;
    color: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(54, 54, 54, 0.913));
    margin: 50px 0 20px 0;
  }
  
  .tailor-request .shops {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .tailor-request .shop {
    margin: 0 10px;
  }
  
  .tailor-request .shop img {
    width: 150px;
    height: 150px;
  }
  
  .tailor-request .shop-name {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .tailor-request button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .tailor-request .details label {
    display: block;
    margin: 10px 0;
  }
  
  .tailor-request .details input[type="text"], 
  .tailor-request .details select, 
  .tailor-request .details textarea {
    padding: 5px;
    font-size: 16px;
    margin-left: 10px;
    width: 100%;
    max-width: 300px; 
    box-sizing: border-box;
  }
  
  .tailor-request .details textarea {
    height: 80px; 
  }
  
  .tailor-request .summary p {
    font-size: 16px;
    margin: 5px 0;
  }
  