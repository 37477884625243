
.product-list-section .product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-list-section .bottom-text{
  background-color: #ffffff;
  color: #000000;
  padding: 10px 0 10px 0;
}

.badge {
  position: absolute;
  bottom: 60%;
  left: 90%;
  width: 15px;
  height: 15px;
  content: "";
  background: #000000;
  border: #ffffff solid 1px;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

.cart-icon {
  position: relative;
  width: 25px;
}
.cart-icon i {
  color: white;
  font-size: 1.2rem
}

@media only screen and (max-width: 768px) {
  .cart-icon i {
    font-size: 1.2rem !important;
  }
}

/* -------- SHOP NAV BAR STYLING STARTS -------- */

.product-list-section .shop-navbar {
  background-color: #000000;
  color: #ffffff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  z-index: 999;
}

.product-list-section .logo {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}

.product-list-section .shop-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.product-list-section .shop-menu li {
  margin-right: 20px;
  padding-top: 6px;
}

.product-list-section .shop-menu a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}

.product-list-section .content {
  padding: 20px;
}

/* -------- SHOP NAV BAR STYLING ENDS -------- */

/* -------- SHOP HEADER STYLING STARTS -------- */

.product-list-section .shop-header {
  display: flex;
  flex-direction: row;
}

.product-list-section .shop-header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.product-list-section .shop-header-content h1 {
  color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.913));
  margin-left: 10px;
  text-align: end;
}

.product-list-section .shop-header-content h1 {
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.product-list-section .shop-header-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.913));
  text-align: end;
  margin-top: 1.5rem;
}

.product-list-section .shop-header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.product-list-section .shop-header-image img {
  width: 100%;
  aspect-ratio: 1 / 1;
  /* height: auto; */
  border-radius: 30px;
}

@media screen and (max-width: 1050px) {
  .product-list-section .shop-header {
    flex-direction: column;
  }

  .product-list-section .shop-header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .product-list-section .shop-header-content h1 {
    font-size: 28px;
    line-height: 60px;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
  }

  .product-list-section .shop-header-content p {
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
  }

  .product-list-section .shop-header-content__people {
    flex-direction: column;
  }

  .product-list-section .shop-header-content__people p {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .product-list-section .shop-header-content h1 {
    font-size: 38px;
    line-height: 60px;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
  }

  .product-list-section .shop-header-content p {
    font-size: 20px;
    line-height: 24px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
  }
}

@media screen and (max-width: 490px) {
  .product-list-section .shop-header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .product-list-section .shop-header-content p {
    font-size: 14px;
    line-height: 24px;
  }
}

/* -------- SHOP HEADER STYLING ENDS -------- */

/* ---------- FOOTER STYLING STARTS ------------- */

.product-list-section .content1 {
  position: relative;
  margin: 130px auto;
  text-align: center;
  padding: 0 20px;
}
.product-list-section .content1 .text {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ffffff;
}
.product-list-section .content1 .p {
  font-size: 2.1875rem;
  font-weight: 600;
  color: #202020;
}
.product-list-section .shop-footer {
  bottom: 0px;
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  padding-top: 20px;
}
.product-list-section .main-content {
  display: flex;
}
.product-list-section .main-content .box {
  flex-basis: 50%;
  padding: 10px 20px;
}
.product-list-section .box h2 {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
}
.product-list-section .box .content {
  margin: 20px 0 0 0;
  position: relative;
}
.product-list-section .box .content:before {
  position: absolute;
  content: "";
  top: -10px;
  height: 2px;
  width: 100%;
  background: #2e2e2e;
}
.product-list-section .box .content:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 15%;
  background: #ffffff;
  top: -10px;
}
.product-list-section .left .content p {
  text-align: justify;
}
.product-list-section .left .content .social {
  margin: 20px 0 0 0;
}
.product-list-section .left .content .social a {
  padding: 0 2px;
}
.product-list-section .left .content .social a span {
  height: 40px;
  width: 40px;
  background: #1a1a1a;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  transition: 0.3s;
}

.product-list-section .center .content .fas {
  font-size: 1.4375rem;
  background: #1a1a1a;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.product-list-section .center .content .text {
  font-size: 1.0625rem;
  font-weight: 500;
  padding-left: 10px;
}
.product-list-section .center .content .phone {
  margin: 15px 0;
}
.product-list-section .right form .text {
  font-size: 1.0625rem;
  margin-bottom: 2px;
  color: #656565;
}
.product-list-section .right form .LinkButton2 {
  margin-top: 10px;
}
.product-list-section .right form input:focus,
.product-list-section .right form textarea:focus {
  outline-color: #3498db;
}
.product-list-section .right form input {
  height: 35px;
}
.product-list-section .right form .LinkButton3 {
  margin-top: 10px;
}

@media screen and (max-width: 900px) {
  .product-list-section footer {
    position: relative;
    bottom: 0px;
  }
  .product-list-section .main-content {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .product-list-section .main-content .box {
    margin: 5px 0;
  }
}

.product-list-section .LinkButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-decoration: none;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.product-list-section .LinkButton:hover {
  background: none;
  border: #ffffff solid 2px;
  color: rgb(255, 255, 255);
}

/* ----------- FOOTER STYLING ENDS -------------- */