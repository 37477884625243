.admin-dashboard {
    margin-bottom: 20px;
    padding: 20px;
    background-color: none;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-header {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .dashboard-search {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .dashboard-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .dashboard-stat {
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-stat h3 {
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .dashboard-stat p {
    font-size: 24px;
    font-weight: bold;
  }
  
  .dashboard-shop-list {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-shop-list h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  
  .dashboard-shop-list ul {
    list-style: none;
    padding: 0;
  }
  
  .dashboard-shop-list li {
    margin-bottom: 10px;
  }
  
  .dashboard-shop-list a {
    text-decoration: none;
    font-size: 16px;
  }
  
  .dashboard-shop-list a:hover {
    text-decoration: underline;
  }

  .dashboard-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .dashboard-buttons .dashboard-button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-buttons .dashboard-button:hover {
    background-color: #515151;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .dashboard-stats {
      flex-direction: column;
    }
  
    .dashboard-stat {
      margin: 10px 0;
    }
  
    .dashboard-buttons {
      flex-direction: column;
    }
  
    .dashboard-buttons .dashboard-button {
      width: 100%;
      text-align: center;
    }
  }
