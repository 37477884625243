.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 5rem;
  background-image: url("../../../assets/whole-site-bg.avif");
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-links_logo {
  margin-right: 2rem;
}

.navbar-links_logo img {
  width: 85px;
  margin-top: 0;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links_container h1,
.navbar-sign h1,
.navbar-menu_container h1,
.navbar-links_container a {
  color: #000000;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0.9rem;
  cursor: pointer;
  text-decoration: none;
}

.navbar-links_container a:hover {
  color: #686868;
}

.navbar-sign .button:hover {
  background: none;
  color: #000000;
  border: #000000 solid 2px;
}

.navbar-sign .button,
.navbar-menu_container .button {
  padding: 0.5rem 1rem;
  color: #ffffff;
  background: #000000;
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  z-index: 1000;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #000000;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.navbar-menu_container p {
  margin: 1rem 0;
  color: #ffffff;
}

.navbar-menu_container a {
  color: #ffffff;
  text-decoration: none;
}

.navbar-menu_container-links-sign {
  display: none;
}

.navbar .user-icon {
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
}

.navbar .cart-icon {
  margin-left: 1rem;
  cursor: pointer;
  color: #000000;
}

.navbar .cart-icon:hover {
  color: #686868;
}

.navbar .dropdown-menu {
  position: absolute;
  top: 3.5rem;
  right: 0;
  background-color: #000000;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  z-index: 999;
}

.navbar .dropdown-menu p {
  margin: 0;
  padding: 0.4rem 0;
  cursor: pointer;
  color: #ffffff;
}

.navbar .dropdown-menu p:hover {
  background-color: #414141;
}

.navbar .dropdown-divider {
  border: none;
  border-top: 1px solid #ddd;
  margin: 0.5rem 0;
}

.navbar .user-initial {
  width: 30px;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.navbar .login-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.navbar .login-text {
  margin-left: 0.5rem;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }

  .navbar-links_logo img {
    width: 75px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 1.5rem;
  }

  .navbar-sign .button {
    display: none;
  }
  .navbar-sign {
    display: visible;
  }

  .navbar-menu_container {
    top: 20px;
  }

  .navbar-menu_container-links-sign {
    display: block;
  }

  .navbar-sign .button,
  .navbar-menu_container .button {
    background: #ffffff;
    color: #000000;
  }
}
