.privacy-policy-section {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .privacy-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-container h1, .privacy-container h2, .privacy-container h3 {
    color: #333333;
    padding: 10px;
  }

  .privacy-container p{
    padding: 10px;
  }
  
  .privacy-container p, .privacy-container ul {
    color: #666666;
  }
  
  .privacy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-container a:hover {
    text-decoration: underline;
  }
  