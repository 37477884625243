@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&display=swap');

h1, h2, h3, h4, h5, h6 {
  font-family: 'EB Garamond', serif;
}

body, p, ul, ol, code, pre {
  font-family: 'Inter', sans-serif;
}

.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: rgb(135, 135, 135);
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(rgb(61, 61, 61), rgb(0, 0, 0));
  border-radius: 25px;
}

img {
  pointer-events: none;
}

body {
  padding: 0;
  margin: 0;
  background-image: url('./assets/whole-site-bg.avif');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Inter', sans-serif;
}
