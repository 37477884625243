.article-section {
  padding-top: 50px;
  background-image: url('../../../assets/article-background.avif');
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 30px;
  overflow: hidden;
}

.line-title {
  margin-bottom: 48px;
  padding-bottom: 16px;
  font-size: 24px;
  text-transform: capitalize;
  width: 400px;
  position: relative;
  color: #000000;
}

.line-title::before,
.line-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  border-radius: 2px;
}

.line-title::before {
  width: 50%;
  background: #ffffff;
}

.line-title::after {
  width: 30px;
  background: #000000;
}

.custom-carousel {
  padding-top: 50px;
  padding-bottom: 50px;
}

.custom-carousel .swiper-slide {
  width: 320px;
  height: 400px;
  margin: 0 15px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center; 
  justify-content: center; 
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.custom-carousel .swiper-slide.active {
  width: 500px;
  box-shadow: 12px 40px 40px rgba(0, 0, 0, 0.25);
}

.custom-carousel .swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: 1;
}

.item-desc-container {
  position: relative;
  z-index: 2; 
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  padding: 0 24px 12px;
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.read-more {
  opacity: 0;
  color: #ffffff;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out;
}

.custom-carousel .swiper-slide:not(.active) .read-more {
  opacity: 1;
}

.custom-carousel .swiper-slide.active .read-more {
  display: none; 
}

.item-desc h3 {
  font-size: 28px;
  color: #ffffff;
}

.item-desc p {
  opacity: 0;
  color: #ffffff;
  transform: translateY(32px);
  transition: all 0.4s ease-in-out 0.2s;
}

.item.active .item-desc p {
  opacity: 1;
  transform: translateY(0);
}

.date-posted {
  font-size: 14px;
  color: #ffffff;
  margin-top: 10px;
  height: auto;
}

@media (max-width: 767px) {
  .custom-carousel .swiper-slide {
    width: 200px;
    height: 280px;
  }
  .custom-carousel .swiper-slide.active {
    width: 270px;
    height: auto;
  }
  .item-desc h3 {
    font-size: 19px;
  }
}
