.about-us-section {
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-section .about-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-section h1 {
    text-align: center;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-section h2 {
    font-size: 2em;
    color: #444;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .about-us-section h3 {
    font-size: 1.5em;
    color: #555;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .about-us-section p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
  }
  
  .about-us-section strong {
    color: #000;
  }
  
  @media (max-width: 768px) {
    .about-us-section .about-container {
      padding: 15px;
    }
  
    .about-us-section h1 {
      font-size: 2em;
    }
  
    .about-us-section h2 {
      font-size: 1.75em;
    }
  
    .about-us-section h3 {
      font-size: 1.25em;
    }
  
    .about-us-section p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-section .about-container {
      padding: 10px;
    }
  
    .about-us-section h1 {
      font-size: 1.75em;
    }
  
    .about-us-section h2 {
      font-size: 1.5em;
    }
  
    .about-us-section h3 {
      font-size: 1.1em;
    }
  
    .about-us-section p {
      font-size: 0.9em;
    }
  }
  