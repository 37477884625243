.footer-section {
  padding-top: 10px;
  background-color: #000000;
}

.footer-section .contact-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 400;
}

.footer-section a {
  text-decoration: none;
  color: inherit;
}

.footer-section .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 6px;
}

.footer-section .item {
  background-color: #000000;
  color: rgb(255, 255, 255);
  padding: 20px;
  text-align: left;
  text-decoration: none;
}

.footer-section .a {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
}

.footer-section .b {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
  padding-left: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .c {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .d {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .e {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .f {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .g {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
  padding-left: 0px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.footer-section .social-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.footer-section .social-icons a {
  color: rgb(255, 255, 255);
  font-size: 24px;
}

.footer-link {
  font-size: 16px; 
}

.footer-section .c .footer-link,
.footer-section .d .footer-link,
.footer-section .e .footer-link {
  font-size: 16px;
}

@media (max-width: 768px) {
  .footer-link {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .footer-section .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  }
  
  .footer-section .a {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  
  .footer-section .b {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
  
  .footer-section .c {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  
  .footer-section .d {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }
  
  .footer-section .e {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
  
  .footer-section .f {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }
  
  .footer-section .g {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
  }
  
  .footer-section .h {
    display: none;
  }
  
  .footer-section .i {
    display: none;
  }
  
  .footer-section .j {
    display: none;
  }
}

@media (max-width: 480px) {
  .footer-section {
    padding: 2rem;
  }
  .footer-section .e {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }
}

@media screen and (max-width: 700px) {
  .footer-section {
    padding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .footer-section {
    padding-left: 4rem;
  }
}

@media screen and (min-width: 1280px) {
  .footer-section {
    padding: 4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .footer-section .grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 6px;
  }
}