.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
}


.flex-slide {
  flex: 1 1 200px;
  position: relative;
  padding: 20px;
  color: #fff;
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
  min-height: 250px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.flex-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.flex-slide:hover .flex-about {
  opacity: 1;
  transform: translateY(0);
}

/* Content inside Flex Slide */
.flex-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 2; 
}

.flex-about {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 2;
}


.flex-about a {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 10px; 
}

.flex-about a:hover {
  background-color: #fff;
  color: #333;
  transform: scale(1.05);
}


.flex-slide.home {
  background-image: url("../../../assets/thrift-menu-img.avif");
}

.flex-slide.creators {
  background-image: url("../../../assets/creators-menu-img.avif");
}

.flex-slide.custom {
  background-image: url("../../../assets/custommade-menu-img.avif");
}

.flex-slide.join {
  background-image: url("../../../assets/join-menu-img.avif");
}

/* Responsive styling */
@media (max-width: 768px) {
  .flex-slide {
    flex: 1 1 100%;
    margin: 10px 0;
  }
  .flex-title {
    font-size: 1.25rem;
  }
  .flex-about p, .flex-about a {
    font-size: 1rem;
  }
}
