.subscription-packages {
    display: block;
    margin: 50px auto 50px;
    max-width: 1170px;
}
.subscription-packages .box{
    border-radius: 5px;
    background: aliceblue;
}
.subscription-packages .box h3{
    text-align: center;
    font-size: 24px;
    color: #FFF;
    margin: 0 10px 0 10px;
    position: relative;
    top: -20px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.subscription-packages h1{
   text-align: center;
   margin-bottom: 1.5em;
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #795548, 0 0 30px #795548, 0 0 40px #795548, 0 0 55px #795548, 0 0 75px #795548, -40px -40px 0px rgba(28,110,164,0);
}

.subscription-packages .box .basic{
    background:linear-gradient(38deg,#000000,#000000);
    color: white;
}
.subscription-packages .box .gold {
    background: linear-gradient(38deg, #FFD700, #FF8C00);
    color: white;
}

.subscription-packages .box .platinum {
    background: linear-gradient(38deg, #E5E4E2, #B0C4DE);
    color: white;
}

.subscription-packages .box p {
    text-align: center;
    padding: 10px 5px;
    font-size: 16px;
    color: rgb(0, 0, 0);
    margin: 0;
}

.subscription-packages .box span {
    display: block;
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 53px;
    margin-bottom: 5px;
}
.subscription-packages .list-items {
    display: block;
    text-align: center;
    font-size: 14px;
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
}

.subscription-packages .flexbox {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 15px;
    justify-content: center;
}
.subscription-packages .flexbox .box{
    width: 280px;
   height: 300px;
  
    box-sizing: border-box;
    margin: 10px 15px;
    margin-bottom: 5em;
    position: relative;
    background-color: rgb(255, 255, 255);
}
.subscription-packages .flexbox .box:after{
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    bottom: -10%;
    left: 5%;
    height: 20px;
    width: 90%;
    background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
}
.subscription-packages .scrollbar{
    height: 240px;
    overflow-y: auto;
    scroll-behavior: smooth;
}