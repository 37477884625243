.terms-of-service-section {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .terms-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .terms-container h1, .terms-container h2, .terms-container h3 {
    color: #333333;
  }
  
  .terms-container p, .terms-container ul {
    color: #666666;
    padding: 10px;
  }
  
  .terms-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  