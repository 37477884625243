.blocked-shop-alert {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    margin: 50px auto;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blocked-shop-alert h2 {
    color: #343a40;
    margin-bottom: 15px;
  }
  
  .blocked-shop-alert p {
    color: #6c757d;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .inactive-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .home-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #232323;
  }
  