.track-your-delivery {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
    color: black;
  }
  
  .track-your-delivery h2 {
    margin-bottom: 20px;
    color: black;
  }
  
  .track-your-delivery p {
    margin-bottom: 10px;
  }
  
  .track-your-delivery select {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    color: black;
  }
  
  .track-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .track-button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  
  .home-button {
    display: block;
    margin-top: 20px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .home-button:hover {
    color: gray;
  }
  
  @media (max-width: 768px) {
    .track-your-delivery {
      padding: 15px;
      margin: 30px 10px;
    }
  
    .track-your-delivery select {
      font-size: 14px;
    }
  
    .track-button, .home-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  