.forgot-password-container {
    max-width: 400px;
    margin: 0 auto 30px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .forgot-password-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .forgot-password-container p {
    font-size: 15px;
    margin-bottom: 20px;
    color: #666666;
  }
  
  .forgot-password-container input[type="email"] {
    width: 100%;
    padding: 14px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .forgot-password-container input[type="email"]:focus {
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.6);
  }
  
  .forgot-password-container button {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  
  .forgot-password-container button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .forgot-password-container button:hover {
    background-color: #313131;
  }
  