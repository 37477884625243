.shop-card-section {
    background: #494949;
    position: relative;
    max-width: 460px;
    height: 300px;
    margin: 20px;
    overflow: hidden;
    border-bottom-right-radius: 20px;
  }
  
  .shop-card-section img {
    width: 100%;
    height: 100%;
  }
  
  .shop-card-section:hover img {
    opacity: 1;
  }
  
  .shop-card-section .details {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    width: 100%;
    height: 100%;
  }
  
  .shop-card-section .details h2 {
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 70px;
    transition: 0.4s;
    transition-property: transform;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.913));
  }
  
  .shop-card-section .details h2 span {
    font-weight: 900;
  }
  
  .shop-card-section:hover .details h2 {
    transform: translateY(-30px);
  }
  
  .shop-card-section .details p {
    margin: 30px 30px 0 30px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    opacity: 0;
    transition: 0.6s;
    transition-property: opacity, transform;
  }
  
  .shop-card-section:hover .details p {
    opacity: 1;
    transform: translateY(-40px);
  }
  
  .shop-card-section .shop-more {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    bottom: -60px;
    transition: 0.6s;
    transition-property: bottom;
  }
  
  .shop-card-section .shop-more span {
    color: #000000;
    text-decoration: none;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: left;
  }
  
  .shop-card-section:hover .shop-more {
    bottom: 0;
  }
  
  .shop-card-section .shop-more .go-to-shop-btn {
    color: #000;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
  }
  
  @media (max-width: 1080px) {
    .shop-card-section {
      flex: 100%;
      max-width: 480px;
    }
  }
  
  @media (max-width: 600px) {
    .shop-card-section {
      flex: 0 0 calc(40% - 0px);
      max-width: calc(45% - 5px);
      height: calc(45vw - 5px);
      margin: 15px;
    }
  }
  
  @media (min-width: 768px) {
    .shop-card-section {
      flex: 0 0 calc(25% - 40px);
      max-width: calc(25% - 40px);
    }
  
    .shop-card-section .details h2 {
      font-size: 28px;
      margin-top: 50px;
    }
  
    .shop-card-section .details p {
      font-size: 16px;
      margin: 20px 20px 0 20px;
    }
  
    .shop-card-section .shop-more span,
    .shop-card-section .shop-more .go-to-shop-btn {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    .shop-card-section {
      flex: 0 0 calc(40% - 0px);
      max-width: calc(45% - 5px);
      height: calc(45vw - 5px);
    }
  
    .shop-card-section .details h2 {
      font-size: 16px;
      margin-top: 45px;
    }
  
    .shop-card-section .details p {
      font-size: 12px;
      margin: 10px;
    }
  
    .shop-card-section .shop-more span,
    .shop-card-section .shop-more .go-to-shop-btn {
      font-size: 12px;
    }
  }
  
  @media (max-width: 400px) {
    .shop-card-section {
      flex: 0 0 calc(40% - 0px);
      max-width: calc(45% - 5px);
      height: calc(45vw - 5px);
      margin: 15px;
    }
  
    .shop-card-section .details h2 {
      font-size: 14px;
      margin-top: 40px;
    }
  
    .shop-card-section:hover .details p {
      opacity: 1;
      transform: translateY(-10px);
      margin-bottom: 20px;
    }
  
    .shop-card-section .details p {
      font-size: 10px;
      margin: 5px 5px 0 5px;
      flex: 100%;
    }
  
    .shop-card-section .shop-more span,
    .shop-card-section .shop-more .go-to-shop-btn {
      font-size: 10px;
    }
  }
  
  @media (max-width: 320px) {
    .shop-card-section {
      flex: 0 0 calc(40% - 0px);
      max-width: calc(45% - 5px);
      height: calc(45vw - 5px);
      margin: 15px;
    }
  }
  