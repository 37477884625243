.admin-edit-shop-rank-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-edit-shop-rank-modal .modal-content {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 400px;
}

.admin-edit-shop-rank-modal h2 {
  color: rgb(0, 0, 0);
  margin: 10px;
}

.admin-edit-shop-rank-modal .modal-content p {
  color: rgb(255, 96, 96);
  margin-bottom: 20px;
  font-size: 16px;
}

.admin-edit-shop-rank-modal .modal-actions {
  display: flex;
  justify-content: flex-end;
}

.admin-edit-shop-rank-modal .modal-actions button {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.admin-edit-shop-rank-modal .modal-actions .save-btn {
  background-color: #000000;
  color: white;
}

.admin-edit-shop-rank-modal .modal-actions .cancel-btn {
  background-color: rgb(223, 76, 76);
  color: white;
}

.admin-edit-shop-rank-modal .modal-actions .save-btn:hover {
    background-color: rgb(84, 84, 84);
    color: white;
}

.admin-edit-shop-rank-modal .modal-actions .cancel-btn:hover {
    background-color: rgb(206, 0, 0);
    color: white;
}  

@media (max-width: 768px) {
  .admin-edit-shop-rank-modal .modal-content {
    width: 90%;
  }
}
