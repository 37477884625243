.cart-items-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-items-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.cart-details {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.cart-details p {
  margin: 5px 0;
  color: #555;
  font-size: 16px;
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item-details h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.item-details p {
  margin: 0;
  color: #555;
  font-size: 14px;
}

.item-details p strong {
  color: #000;
}

.cart-item-buttons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cart-item-buttons .button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-item-buttons .button:hover {
  background-color: #414141;
}

.cart-item-buttons .delete-btn {
  background-color: #d9534f;
}

.cart-item-buttons .delete-btn:hover {
  background-color: #d41811;
}

.checkout-btn {
  display: block;
  margin: 20px auto;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.checkout-btn:hover {
  background-color: #414141;
}

.loading,
.no-items {
  text-align: center;
  font-size: 18px;
  color: #888;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image img {
    width: 100%;
    max-width: 300px;
  }
}
