/* admin-shop-subscription-package.css */

.admin-subscription-packages {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-subscription-packages h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.admin-subscription-packages .error-message {
  color: #ff6347;
  font-weight: bold;
}

.admin-subscription-packages p {
  margin-bottom: 10px;
}

.admin-subscription-packages .subscription-package {
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-subscription-packages .subscription-package h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.admin-subscription-packages .subscription-package p {
  font-size: 16px;
  margin-bottom: 5px;
}

.admin-subscription-packages .subscription-package hr {
  margin-top: 15px;
  margin-bottom: 10px;
  border: none;
  border-top: 1px solid #ddd;
}

.admin-subscription-packages .loading-message {
  text-align: center;
  font-style: italic;
  color: #888;
}

.price-details {
  font-size: 16px; 
  margin-bottom: 5px; 
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .admin-subscription-packages {
    padding: 10px;
  }
  
  .admin-subscription-packages .subscription-package {
    padding: 10px;
  }
  
  .admin-subscription-packages .subscription-package h2 {
    font-size: 20px;
  }
  
  .admin-subscription-packages .subscription-package p {
    font-size: 14px;
  }
}
