.login-container {
  max-width: 400px;
  margin: 0 auto 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container img.logo {
  width: 35%;
  max-width: 200px;
}

.login-container h1 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.login-container p {
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
  color: #666666;
}

.login-container input[type="email"],
.login-container input[type="password"],
.password-input input[type="text"] {
  width: 100%;
  padding: 14px;
  padding-right: 50px; 
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.login-container input[type="email"]:focus,
.login-container input[type="password"]:focus,
.password-input input[type="text"]:focus {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.6);
}

.login-container .password-input {
  position: relative;
}

.login-container .password-toggle-icon {
  font-size: 24px;
  color: #949494;
  position: absolute;
  top: 39%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.login-container button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; 
}

.login-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-container button:hover {
  background-color: #313131;
}

.login-container .inline-links {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px; 
}

.login-container .inline-links a {
  color: #000000;
  text-decoration: none;
  font-weight: normal;
}

.login-container .inline-links a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .login-container {
    max-width: 90%;
  }
  
  .login-container .inline-links {
    flex-direction: row; 
  }
}
