.creatorproductlist .creatorproduct-container { 
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.creatorproductlist .intro {
  text-align: center;
  margin-bottom: 30px;
}

.creatorproductlist .intro  h2 {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(54, 54, 54, 0.913));
  margin: 50px 0 20px 0;
}

.creatorproductlist .intro p {
  font-size: 16px;
  margin: 5px 0;
}

.creatorproductlist .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem 0;
}

.creatorproductlist .pagination button {
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.creatorproductlist .pagination button:hover {
  background-color: #353535;
}

.creatorproductlist .pagination .active {
  background-color: #000000;
}

.creatorproductlist .pagination button:disabled {
  background-color: #464646;
  cursor: not-allowed;
}

.creatorproductlist .loading,
.creatorproductlist .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .creatorproductlist .creatorproduct-container {
    padding: 1rem;
  }
}
