.faq-section {
  padding: 20px;
  background-color: #f5f5f5;
}

.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

.faq-container h1 {
  color: #333333;
  padding: 10px;
  text-align: center;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  cursor: pointer;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #333333;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #e7e7e7;
}

.faq-answer {
  padding: 10px;
  color: #666666;
  background-color: #ffffff;
  border-left: 3px solid #000000;
  margin-top: 10px;
}
