.signup-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9; 
  border-radius: 10px; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); 
}

.signup-form h2 {
  font-size: 32px; 
  text-align: center;
  margin-bottom: 20px;
  color: #333333; 
}

.signup-form label {
  display: block;
  font-size: 18px; 
  margin-bottom: 8px;
}

.signup-form input,
.signup-form select,
.signup-form textarea {
  width: 100%;
  padding: 14px;
  margin-bottom: 16px; 
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.signup-form label {
  display: flex;
  align-items: center;
  gap: 0.5em; 
}

.signup-form input[type="checkbox"] {
  width: auto;
  margin: 0;
}

.signup-form .terms-text {
  font-size: 12px;
}

.signup-form input:focus,
.signup-form select:focus 
.signup-form textarea:focus {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.6); 
}

.signup-form button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 5px;
  font-size: 20px; 
  cursor: pointer;
  background-color: #000000; 
  color: #ffffff; 
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.signup-form button:hover {
  background-color: #313131; 
}
.signup-form .error-message {
  color: red;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.signup-form-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.options-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.options-buttons h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.options-buttons button {
  background-color: #000000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.options-buttons button:hover {
  background-color: #4e4e4e;
}

.options-content {
  text-align: center;
}

.options-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.options-content p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

@media (min-width: 768px) {
  .signup-form-options {
    flex-direction: row;
    justify-content: space-between;
  }

  .options-buttons {
    order: -1;
    max-width: 40%;
    margin-bottom: 0;
  }

  .options-content {
    max-width: 50%;
    text-align: left;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .signup-form {
    max-width: 90%;
  }

  .signup-form input,
  .signup-form textarea {
    padding: 12px;
  }
}
