.admin-shop-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
  margin: 20px;
}

.toolbar-item {
  display: flex;
  align-items: center;
}

.toolbar-item:not(:last-child) {
  margin-right: 20px;
}

.status-active {
  color: rgb(74, 199, 74);
}

.status-inactive {
  color: red;
}

.button.see-orders {
  background-color: #000000;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button.see-orders:hover {
  background-color: #464646;
}


.button.sign-out {
  background-color: #ff4d4d;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.button.sign-out:hover {
  background-color: #ff3333;
}

@media (max-width: 768px) {
  .admin-shop-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .toolbar-item {
    margin: 10px 0;
    width: 100%;
    justify-content: space-between;
  }

  .toolbar-item:not(:last-child) {
    margin-right: 0;
  }

  .button.sign-out {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}