.admin-subscription-packages {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .admin-subscription-packages h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .admin-subscription-packages h3 {
    margin-top: 20px;
    color: #555;
  }
  
  .admin-subscription-packages form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .admin-subscription-packages label {
    font-weight: bold;
    color: #666;
  }
  
  .admin-subscription-packages input,
  .admin-subscription-packages textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
  
  .admin-subscription-packages input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .admin-subscription-packages textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .admin-subscription-packages button {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-subscription-packages button:hover {
    background-color: #3c3c3c;
  }
  
  .admin-subscription-packages hr {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #ddd;
  }
  
