.shop-application-complete {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .shop-application-complete .icon {
    font-size: 48px;
    color: #4caf50; 
    margin-bottom: 20px;
  }
  
  .shop-application-complete h2 {
    font-size: 30px;
    margin-bottom: 40px;
    margin-top: 40px;
    color: #333333;
  }
  
  .shop-application-complete p {
    font-size: 18px;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .shop-application-complete .home-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    color: #ffffff;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .shop-application-complete .home-button:hover {
    background-color: #313131;
  }
  
  @media screen and (max-width: 768px) {
    .shop-application-complete {
      max-width: 90%;
      padding: 20px;
    }
  }
  