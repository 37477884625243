.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-modal .modal-content {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    width: 400px;
  }
  
  .confirmation-modal .modal-content p {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .confirmation-modal .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .confirmation-modal .modal-actions button {
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .confirmation-modal .modal-actions button:first-child {
    background-color: #000000;
    color: white;
  }
  
  .confirmation-modal .modal-actions button:last-child {
    background-color: #dc3545;
    color: white;
  }
  
  .confirmation-modal .modal-actions button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .confirmation-modal .modal-content {
      width: 90%;
    }
  }
  