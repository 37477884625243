.creativesHubHeader-section {
  display: flex;
  flex-direction: row;
  background-color: black;
  color: #ffffff;
  padding: 20px;
  gap: 20px;
  box-sizing: border-box;
}

.creativesHubHeader-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.creativesHubHeader-content h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 1.2;
  margin-top: 1.5rem;
  text-align: left;
}

.creativesHubHeader-content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 1rem;
  text-align: left;
}

.creativesHubHeader-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.creativesHubHeader-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.header-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 20px;
}

.header-buttons button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: none;
  border: #ffffff solid 2px;
  border-radius: 55px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.header-buttons button:hover {
  background-color: #ffffff;
  color: #000000;
}

@media only screen and (max-width: 1050px) {
  .creativesHubHeader-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .creativesHubHeader-content {
    align-items: center;
    margin: 0 0 2rem;
  }

  .header-buttons {
    justify-content: center;
  }

  .creativesHubHeader-content h1,
  .creativesHubHeader-content p {
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .creativesHubHeader-content h1 {
    font-size: 28px;
    line-height: 1.2;
  }

  .creativesHubHeader-content p {
    font-size: 16px;
  }

  .header-buttons button {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}
