.admin-shop-list {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.admin-shop-list h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.admin-shop-list .error-message {
  color: #ff3333;
}

.admin-shop-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.admin-shop-list th, .admin-shop-list td {
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
}

.admin-shop-list th {
  background-color: #f2f2f2;
}

.admin-shop-list .add-shop-section {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
}

.admin-shop-list label {
  display: block;
  margin-bottom: 5px;
}

.admin-shop-list input, .admin-shop-list textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.admin-shop-list .add-shop-btn {
  background-color: #000000;
  color: #fff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.admin-shop-list .add-shop-btn:hover {
  background-color: #424242;
}

/* Style for links inside the table */
.admin-shop-list a {
  color: #337ab7;
  text-decoration: none;
}

.admin-shop-list a:hover {
  text-decoration: underline;
}

.admin-shop-list a.button-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.admin-shop-list a.button-link:hover {
  background-color: #424242;
  text-decoration: none;
}

.admin-shop-list button {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.admin-shop-list button:hover {
  background-color: #d41811;
}

.admin-shop-list .shop-rank-btn{
  display: inline-block;
  padding: 8px 12px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  border-radius: 40px;
}
.admin-shop-list .shop-rank-btn:hover{
  background-color: #747474;
}

.toggle-label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label .back {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.3s;
}

.toggle-label .toggle {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-label input[type=checkbox]:checked + .back {
  background-color: #2196F3;
}

.toggle-label input[type=checkbox]:checked + .back .toggle {
  transform: translateX(26px);
}

.toggle-label .label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  right: 5px;
  text-align: center;
  font-size: 14px;
  color: white;
  pointer-events: none;
}

.toggle-label .label.on {
  left: 5px;
}

.toggle-label .label.off {
  right: 5px;
}

.admin-shop-list .subscription-package-btn {
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  border: 1px solid #000; 
  border-radius: 4px;
  background-color: #fff; 
  color: #000; 
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-shop-list .subscription-package-btn:hover {
  background-color: #f0f0f0; 
}

.subscription-package-btn:active {
  background-color: #d9d9d9;
}