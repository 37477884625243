.creatives-hub-app-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .creatives-hub-app-info .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .creatives-hub-app-info .card {
    background-color: #000;
    color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .creatives-hub-app-info .card:hover {
    transform: translateY(-10px);
  }
  
  .creatives-hub-app-info .card-content h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .creatives-hub-app-info .card-content p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .creatives-hub-app-info .card-btn {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .creatives-hub-app-info .card-btn:hover {
    background-color: #fff;
    color: #000;
  }
  
  @media (max-width: 768px) {
    .creatives-hub-app-info .card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .creatives-hub-app-info .card {
      width: 100%;
    }
  }
  