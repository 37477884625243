.product-card {
    width: 300px;
    margin: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1.9);
    border-radius: 8px;
    overflow: hidden;
  }
  
.product-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-card .product-details {
    padding: 16px;
    color: #ffffff;
  }
  
  .product-card .product-details h2 {
    margin: 10px;
    color: #ffffff;
  }
  
  .product-card .product-details p {
    margin-bottom: 16px;
  }
  
  .product-card .product-details button {
    padding: 8px 16px;
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .product-card .product-details button + button {
    margin-left: 8px;
  }
  
  .product-card .custom-dots li button:before {
    color: rgb(255, 255, 255);
  }

  .product-card .sale-tag {
    position: relative;
    top: 0;
    left: 0;
    padding: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #fa0000;
    border-radius: 5px;
  }
  
  .product-card .original-price {
    text-decoration: line-through;
    color: #888;
    font-size: 0.9rem;
  }
  
  .product-card .product-price {
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  
  .product-card .price-container {
    display: flex;
    align-items: start;
    gap: 10px;
  }
  
  .product-card .sale-tag {
    font-size: 12px;
    font-weight: bold;
    color: #fa0000;
  }

  .product-card  .product img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
  
  .product-card  .product .description {
    text-align: center;
    margin: 10px 0;
  }
  
  .product-card .addToCartBttn {
    background-color: transparent;
    border: 2px solid rgb(19, 19, 19);
    min-width: 100px;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .product-card .addToCartBttn:hover {
    background-color: rgb(19, 19, 19);
    color: white;
  }

  .product-card .products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    justify-items: center;
  }
  
  .product-card .products-title {
    filter: drop-shadow(0px 4px 4px rgba(253, 253, 253, 0.913));
  }
  
  
  
  @media (min-width: 1024px) {
    .product-card .products {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(
        3,
        1fr
      );
      grid-gap: 20px;
      justify-items: center;
    }
  }