/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Shop List Section */
.shop-list-section {
  margin-bottom: 30px;
}

.shop-list-section .shop-list-container {
  margin: 30px;
}

.shop-list-section .row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.shop-list-section .small-title {
  text-align: center;
  font-size: 28px;
  color: #000000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.913));
  margin: 50px 0 20px 0;
}

/* Search Container */
.shop-list-section .search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.shop-list-section input[type="text"] {
  flex: 1;
  max-width: 400px;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s;
}

.clear-button {
  margin-left: 10px;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.clear-button:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}

.clear-button:focus {
  outline: none;
}

/* Pagination Container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #ccc;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  margin: 0 10px;
}

.pagination-button:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}

.pagination-button:disabled {
  background-color: #f0f0f0;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

/* Media Queries */
@media (max-width: 768px) {
  .shop-list-section .search-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .shop-list-section input[type="text"] {
    margin-bottom: 10px;
  }

  .clear-button {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .shop-list-section .row {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .shop-list-section .small-title {
    font-size: 20px;
  }
}
