@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'EB Garamond', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}



img {
  pointer-events: none;
}


