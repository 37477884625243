.admin-edit-shop-subscription-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .admin-edit-shop-subscription-modal .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .admin-edit-shop-subscription-modal .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .admin-edit-shop-subscription-modal .save-btn, .cancel-btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .admin-edit-shop-subscription-modal .save-btn {
    background-color: #000000;
    color: white;
  }
  
  .admin-edit-shop-subscription-modal .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  